<template>
  <div>
    <portal to="title-page">
      <h1 v-if="tour">{{tour.name}}</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          mdi-plus
        </v-icon>
        <span class="title font-weight-light">Crear tarifa</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-select
            v-model="rate.type"
            :items="typeRates"
            item-text="text"
            item-value="type"
            prepend-icon="mdi-currency-usd"
            :rules="[v => !!v || 'La tarifa es requerida']"
            label="Seleccione la tarifa"
            required
          ></v-select>
          <v-menu
            ref="menu1"
            v-model="menupicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                readonly
                label="Periodo de fechas"
                hint="MM/DD/YYYY format"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :rules="[rules.rangeDates]"
              ></v-text-field>
                <!-- @blur="date = parseDate(dateFormatted)" -->
            </template>
            <v-date-picker v-model="dates" range no-title ></v-date-picker>
          </v-menu>
          <v-row >
            <v-col cols="12" md="12" class="my-5">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <h4 v-if="includeATVs" class="error--text my-0">*Precios por persona</h4>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="rate.adult"
                :rules="[rules.required,rules.number]"
                :label="`Precio adulto` + (includeATVs ? ' Single Atv' : '')"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="includeATVs" cols="12" md="6" class="py-0">
              <v-text-field
                v-model="rate.adultShared"
                :rules="[rules.required,rules.number]"
                :label="`Precio adulto` + (includeATVs ? ' Shared Atv' : '')"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="!includeATVs" cols="12" md="6" class="py-0">
              <v-text-field
                v-model="rate.elderly"
                :rules="[rules.required,rules.number]"
                :label="`Precio adulto mayor`"
                prefix="$"
                :prepend-icon="mdiHumanCane"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="rate.child"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="rate.infant"
                :rules="[rules.required,rules.number]"
                label="Precio Infante"
                prefix="$"
                :prepend-icon="mdiBabyCarriage"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!validForm"
            color="primary"
            class="mr-4"
            @click="validateForm"
          >
            Agregar
          </v-btn>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
      </v-card-actions> -->
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// import TarifaTour from "@/models/TarifaTour.js";
//import Vue from "vue";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { mdiHumanMaleChild, mdiBabyCarriage, mdiHumanMale, mdiClose, mdiArrowLeft } from '@mdi/js';
import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
import Tour from "@/models/Tour";
export default {
  name: "RateCreate",
  data() {
    return {
      mdiHumanMaleChild:mdiHumanMaleChild,
      mdiBabyCarriage:mdiBabyCarriage,
      mdiHumanCane:mdiHumanCane,
      mdiHumanMale:mdiHumanMale,
      mdiClose:mdiClose,
      mdiArrowLeft:mdiArrowLeft,
      tourModel:new Tour,
      tour:null,
      includeATVs:null,
      rate:{
        type:''
      },
      ////////////////////////////////////////////////////////////////////////////
      menupicker:false,
      dates:['', ''],
      ////////////////////////////////////////////////////////////////////////////
      validForm: true,
      rules : {
        required : v => v && v.length > 0 || 'Campo requerido',
        number : v => {
          const pattern = /^[0-9]+([.][0-9]+)?$/
          return pattern.test(v) || 'Numero invalido'
        },
        rangeDates : v => {
          let dates = v.split(' ~ ');
          if (dates.length == 1) {
            return 'Ingrese la fecha de fin';
          }
          let start = this.$moment(dates[0]);
          let end = this.$moment(dates[1]);
          if (end <= start) {
            return 'La fecha de fin debe ser mayor a la fecha de inicio';
          }
          return true;
        }
      },
      typeRates: [
        {type:'local', text:'Local (Quintanarroenses)'},
        {type:'regular', text:'Regular (Nacional y Extranjero)'},
      ],
      snackbar:{
        show:false,
        color:'',
        message:''
      }
    }
  },
  methods:{
    validateForm() {
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        let startDateMoment = this.$moment(this.dates[0]);
        let startDate = firebase.firestore.Timestamp.fromDate(startDateMoment.toDate());
        let endDateMoment = this.$moment(this.dates[1]);
        let endDate = firebase.firestore.Timestamp.fromDate(endDateMoment.toDate());
        let firstValidation;
        if (this.rate.type == 'local') {//dependiendo del tipo de tarifa buscamos en la colleccion correspondiente
          firstValidation = this.tourModel.searchLocalRatesAfterEndDate(this.$route.params.tourId, startDate).get();
        } else {
          firstValidation = this.tourModel.searchRegularRatesAfterEndDate(this.$route.params.tourId, startDate).get();
        }
        firstValidation.then(qSnap => {
          let traslaped = false;
          let docStartDateMoment;
          let docEndDateMoment;
          let docRate;
          qSnap.forEach(doc => {
            docRate = doc.data();
            docStartDateMoment = this.$moment(docRate.startDate.toDate())
            docEndDateMoment = this.$moment(docRate.endDate.toDate())
            let startTraslaped = startDateMoment >= docStartDateMoment && startDateMoment <= docEndDateMoment;
            let endTraslaped = endDateMoment >= docStartDateMoment && endDateMoment <= docEndDateMoment;
            let startTraslapedNR = docStartDateMoment >= startDateMoment && docStartDateMoment <= endDateMoment;
            let endTraslapedNR = docEndDateMoment >= startDateMoment && docEndDateMoment <= endDateMoment;
            if (startTraslaped || endTraslaped || startTraslapedNR || endTraslapedNR) {
              traslaped = true;
              return;
            }
          })
          if (docRate) {
            docRate.moment = this.$moment;
          }
          if (traslaped) {
            this.showSnackbarError(`Existe una tarifa del ${docRate.formatStartDate()} al ${docRate.formatEndDate()}`);
            loading.hide();
          } else {
            this.rate.startDate = startDate;
            this.rate.endDate = endDate;
            let collectionRates = this.rate.type == 'local' ? 'localRates' : 'regularRates';
            this.tour._ref.collection(collectionRates).add(this.rate).then(()=>{
              loading.hide();
              this.showSnackbarSuccess(`La tarifa se agrego correctamente`);
              this.$router.push({path:`/backend/admin/tours/${this.tour.id}/rates`});
            })
            .catch((error) => {
              loading.hide()
              console.log('error', error);
              this.showSnackbarError(`Error al crear la tarifa, intente de nuevo o contacte al administrador`);
            });
          }
        })
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  mounted() {
    this.tourModel.findByTour(this.$route.params.tourId).onSnapshot(snap => {
      this.tour = {id:snap.id,_ref:snap.ref,... snap.data()};
      this.includeATVs = this.tour.includeATVs == 'yes'
    })
  },
  computed: {
    dateRangeText () {
      let datesStr = this.dates.join(' ~ ');
      if (datesStr == ' ~ ') {
        return '';
      }
      return datesStr;
    },
  }
}
</script>